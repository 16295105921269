export default {
  id: '3f6f81cc76',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-navbar" data-name=login><div class=page-content><div class="card ml-3 mr-3"><div class=content><div class="margin-top text-align-center mb-3"><div class=text-align-center><img src=static/images/logo-color.png width=50% alt=""></div></div><div class="block h-100 no-margin"><div class="text-align-center mb-3"><h3 class=font-20>Informe seus dados para acessar</h3></div><form class="was-validated needs-validation" novalidate><div class="list form-list no-margin margin-bottom"><ul><li class="item-content item-input item-input-with-value is-valid"><div class=item-inner><div class="item-title item-floating-label">Informe seu CPF</div><div class=item-input-wrap><input type=text name=usuario class="input-with-value cpf" id=usuarioLogin value=""></div></div></li></ul></div><div class="list form-list no-margin margin-bottom"><ul><li class="item-content item-input item-input-with-value is-valid"><div class=item-inner><div class="item-title item-floating-label">Informe sua Senha</div><div class=item-input-wrap><input type=password name=senha class=input-with-value id=senhaLogin value=""></div></div></li></ul></div><button type=button class="button button-fill button-large button-raised margin-bottom color-theme btnAutenticar">Acessar</button></form><h3 class="font-16 color-theme text-align-center mt-3">Esqueceu a Senha ou é seu Primeiro Acesso?</h3><p class="margin-bottom text-align-center"><a href=/esqueci/ class="color-theme font-14 font-500 link">Clique aqui!</a></p></div></div></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};