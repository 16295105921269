
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '772fce6f46',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="popup aviso"><div class=view><div class="page no-swipeback" data-name=grid><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title>Detalhes da Fatura</div><div class=right><!-- Link to close popup --> <a class="link popup-close iconeX"><i class="fal fa-times"></i></a></div></div></div><div class="page-content pl-2 pr-2"><div class="card shadow-sm mb-4"><div class=card-body><div class="row m-0 p-0"><div class=col-100><h5 class="title text-align-center"><small>';
      r += c(ctx_1.fatura.descricao, ctx_1);
      r += '</small></h5></div></div></div><div class="card border-0"><div class="card-body conteudo pr-2 pl-2"><!-- select Amount --><div class="row margin-bottom"><div class="col-100 text-align-center margin-bottom"><h2 class=text-align-center><span class=text-muted>VALOR R$ ';
      r += c(ctx_1.fatura.valor, ctx_1);
      r += '</span></h2></div></div><!-- Breakdown payment  --><div class=row><div class=col><p>Data de Vencimento:</p></div><div class="col-auto text-align-right"><p class=text-muted>';
      r += c(ctx_1.fatura.vencimento, ctx_1);
      r += '</p></div></div><div class="row margin-bottom"><div class=col><p>Código da Fatura:</p></div><div class="col-auto text-align-right"><p class=text-muted>';
      r += c(ctx_1.fatura.fatura, ctx_1);
      r += '</p></div></div><div class="row margin-bottom foont-weight-bold"><div class=col><p>Situação:</p></div><div class="col-auto text-align-right"><p class="tag text-white bg-';
      r += c(ctx_1.fatura.status_class, ctx_1);
      r += ' border-';
      r += c(ctx_1.fatura.status_class, ctx_1);
      r += '">';
      r += c(ctx_1.fatura.status, ctx_1);
      r += '</p></div></div><div class="font-weight-bold text-align-center text-muted">';
      r += c(ctx_1.fatura.invoice_id, ctx_1);
      r += '</div><br>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.fatura.expirada, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="row m-0 p-0"><div class="col-100 m-0 p-0"><h1 class="mb-0 text-color-theme">Oops!...</h1><h5 class=mb-3>Sua fatura expirou!</h5><p class="text-muted mb-4"><small>Mas não se preocupe, você poderá gerar a 2ª clicando abaixo!</small></p><div class="row m-0 p-0"><div class="col-100 m-0 p-0"><div class="list form-list no-margin margin-bottom-half"><ul><li class="item-content item-input item-input-with-value is-valid"><div class=item-inner><div class="item-title item-floating-label">Novo Vencimento</div><div class=item-input-wrap><div class=item-input-wrap><input type=text readonly=readonly name=dataSegundaVia class="input-with-value text-muted" style=font-size:14px id=dataSegundaVia placeholder="Selecione uma data"> <input type=hidden name=invoice_id id=invoice_idSegundaVia value=';
          r += c(ctx_2.fatura.invoice_id, ctx_2);
          r += '></div></div></div><div class="item-media align-self-center"><button class="button p-1 chip btn btn-default margin-right-half btnGerarSegundaFatura"><span class=chip-label>Gerar 2º Via</span></button></div></li></ul></div></div></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.fatura.pending, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<!-- Coupon code --><div class="row m-0 p-0"><div class="col-100 m-0 p-0"><div class="list form-list no-margin margin-bottom-half"><ul><li class="item-content item-input item-input-with-value is-valid"><div class=item-inner><div class="item-title item-floating-label">Código de Barras</div><div class=item-input-wrap><textarea type=text readonly=readonly name=codigobarras class="input-with-value text-muted codigobarras" value=';
          r += c(ctx_2.fatura.identification, ctx_2);
          r += ' style=font-size:14px></textarea></div></div><div class="item-media align-self-center"><button class="button p-1 chip color-gray border-gray margin-right-half btnCopiarCodigoBarras" data-clipboard-target=.codigobarras><span class=chip-label>Copiar</span></button></div></li></ul></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div><div class=card-footer><div class=mb-1><a href=';
      r += c(ctx_1.fatura.link, ctx_1);
      r += ' class="external link mr-1 ml-1" target=_system><i class="fal fa-file-invoice"></i><h6 class=ml-1>Visualizar Fatura</h6></a></div></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    