export default {
  id: '4c95f86e7f',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-swipeback" data-name=sobre><div class="navbar no-hairline"><div class=navbar-bg></div><div class="navbar-inner sliding text-align-center"><div class=left></div><div class=title>Alteração de Senha</div><div class=right></div></div></div><div class="toolbar toolbar-bottom"><div class=toolbar-inner><a href=/mapa/ class=link><i class="fas fa-2x fa-map-marked-alt"><span>MAPA</span></i></a> <a href=/grid/ class=link><i class="fas fa-2x fa-th"><span>GRID</span></i></a> <a href=/distancia/ class=link><i class="fas fa-2x fa-drafting-compass"><span>ROTA</span></i></a> <a href=/excecoes/ class=link><i class="fas fa-2x fa-exclamation-triangle"><span>EXCEÇÕES</span></i></a> <a href=# class="link panel-open link-active" data-panel=right><i class="fas fa-2x fa-ellipsis-h"><span>INFOS</span></i></a></div></div><div class="page-content hide-navbar-on-scroll" style="background-color: #FFF;"><div class=card><div class="list mt-0"><form id=formCadastroSenha><ul><li><a class=item><div class=item-content><div class=item-inner><div class=item-title>Senha Atual</div></div></div><input type=password name=Usuario[senha_atual] class=usuario_senha_atual></a></li><li><a class=item><div class=item-content><div class=item-inner><div class=item-title>Nova Senha</div></div></div><input type=password name=Usuario[nova_senha] class=usuario_nova_senha></a></li><li><a class=item><div class=item-content><div class=item-inner><div class=item-title>Repita a Nova Senha</div></div></div><input type=password name=Usuario[nova_senha_repita] class=usuario_senha_repita></a></li><li><div class="block block-strong row"><div class=col><a class="button btnSalvarCadastroSenha" href=#>ALTERAR MINHA SENHA</a></div></div></li></ul></form></div></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};