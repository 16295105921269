
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '5824ab7b7c',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=popup><div class=page data-name=reserva><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title><p class="text-muted no-margin-bottom"><i class="fal fa-calendar-alt"></i> ';
      r += c(ctx_1.titulo, ctx_1);
      r += '</p></div><div class=right><!-- Link to close popup --> <a class=link href=/reservas/ ><i class="fal fa-times"></i></a></div></div></div><div class="page-content pl-2 pr-2"><div class="card card-style"><div class="content mt-3 pb-0 mb-0"><h5 class=mb-0>';
      r += c(ctx_1.reserva.dependencia, ctx_1);
      r += '</h5></div><div class="divider mb-0"></div><a class=content><div class=d-flex><div class=ps-1><h6 class="font-600 font-12 opacity-50 mb-0">';
      r += c(ctx_1.reserva.condominio, ctx_1);
      r += ' - ';
      r += c(ctx_1.reserva.unidade, ctx_1);
      r += '</h6><h6 class="font-600 font-12 opacity-50 mb-0">Código da Reserva: ';
      r += c(ctx_1.reserva.codigo, ctx_1);
      r += '</h6><div class="color-theme font-12 opacity-50 font-500">';
      r += c(ctx_1.reserva.regressivo, ctx_1);
      r += '</div><div class="badge bg-red-dark font-10 rounded-s">Cancelamento: ';
      r += c(ctx_1.reserva.cancelamento, ctx_1);
      r += '</div></div><div class="ms-auto align-self-center text-center pe-1"><div class="ms-auto align-self-center"><span class="badge ';
      r += c(ctx_1.reserva.status_css, ctx_1);
      r += ' font-10">';
      r += c(ctx_1.reserva.status, ctx_1);
      r += '</span></div><h5 class="mb-0 color-green-dark">';
      r += c(ctx_1.reserva.data, ctx_1);
      r += '</h5></div></div></a><div class="divider mb-0 mt-n2"></div><div class="pt-1 px-3 mt-2"><h5 class="mb-0 color-gray-dark font-16">Mais informações:</h5><div class="font-400 font-12">';
      r += c(ctx_1.reserva.descricao, ctx_1);
      r += '</div></div><div class=content>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.reserva.cancelar, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="divider mb-0 mt-n2"></div><div class=text-align-center><a href=# class="btn btn-l rounded-s text-uppercase font-700 shadow-s bg-gray btnCancelarReserva" data-codigo=';
          r += c(ctx_2.reserva.codigo, ctx_2);
          r += '><i class="fal fa-calendar-times"></i> Cancelar Reserva</a></div>';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="divider mb-0 mt-n2"></div><div class="text-align-center font-400 font-12 color-red-dark">';
          r += c(ctx_2.reserva.motivo, ctx_2);
          r += '</div>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    