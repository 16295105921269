
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '09f861927f',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page page-home" data-name=home><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link panel-open" data-panel=left><i class="fal fa-bars"></i></a></div><div class=title><div class=logo-small><h5>Reservas de Espaços</h5></div></div><div class=right><a href=/inicial/ class=link><i class="fal fa-home"></i></a></div></div></div><div class="page-content pl-2 pr-2"><div class="card card-style mb-2"><div class="content mt-3 pb-0 mb-0"><h3 class="font-18 color-orange-dark text-center font-600">Reservas em Andamento</h3>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.reservas_existe, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.reservas, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="content mt-3 pb-0 mb-0"><h5 class="mb-0 font-16 font-600">';
              r += c(ctx_3.dependencia, ctx_3);
              r += '</h5></div><a href=/reserva/';
              r += c(ctx_3.codigo, ctx_3);
              r += '/visualizar/ class=content><div class=d-flex><div class=ps-1><h6 class="font-600 font-12 opacity-50 mb-0">';
              r += c(ctx_3.condominio, ctx_3);
              r += ' - ';
              r += c(ctx_3.unidade, ctx_3);
              r += '</h6><h6 class="font-600 font-12 opacity-50 mb-0">Código da Reserva: ';
              r += c(ctx_3.codigo, ctx_3);
              r += '</h6><div class="color-theme font-12 opacity-50 font-500">';
              r += c(ctx_3.regressivo, ctx_3);
              r += '</div><div class="badge bg-red-dark font-10 rounded-s">Cancelamento: ';
              r += c(ctx_3.cancelamento, ctx_3);
              r += '</div></div><div class="ms-auto align-self-center text-center pe-1"><div class="ms-auto align-self-center"><span class="badge ';
              r += c(ctx_3.status_css, ctx_3);
              r += ' font-10">';
              r += c(ctx_3.status, ctx_3);
              r += '</span></div><h5 class="mb-0 color-green-dark">';
              r += c(ctx_3.data, ctx_3);
              r += '</h5></div></div></a><div class="divider mb-0 mt-n2"></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="col-100 text-align-center font-12">Nenhuma reserva realizada até o momento.</div>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div><div class="divider m-1"></div><div class="text-align-center mt-2 mb-2"><a href=/reserva/passo1/ class="btn btn-l rounded-s text-uppercase font-700 shadow-s bg-orange-light"><i class="fal fa-calendar-alt"></i> Nova Reserva</a></div><div class="divider m-1"></div><div class="card card-style mb-1 mt-2"><div class="content mt-3 pb-0 mb-0"><h3 class="font-18 color-orange-dark text-center font-600">Histórico de Reservas</h3>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.historico_existe, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.historico, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="content mt-3 pb-0 mb-0"><h5 class="mb-0 font-16 font-600">';
              r += c(ctx_3.dependencia, ctx_3);
              r += '</h5></div><a href=/reserva/';
              r += c(ctx_3.codigo, ctx_3);
              r += '/visualizar/ class=content><div class=d-flex><div class=ps-1><h6 class="font-600 font-12 opacity-50 mb-0">';
              r += c(ctx_3.condominio, ctx_3);
              r += ' - ';
              r += c(ctx_3.unidade, ctx_3);
              r += '</h6><h6 class="font-600 font-12 opacity-50 mb-0">Código da Reserva: ';
              r += c(ctx_3.codigo, ctx_3);
              r += '</h6><div class="color-theme font-12 opacity-50 font-500">';
              r += c(ctx_3.regressivo, ctx_3);
              r += '</div><div class="badge bg-red-dark font-10 rounded-s">Cancelamento: ';
              r += c(ctx_3.cancelamento, ctx_3);
              r += '</div></div><div class="ms-auto align-self-center text-center pe-1"><div class="ms-auto align-self-center"><span class="badge ';
              r += c(ctx_3.status_css, ctx_3);
              r += ' font-10">';
              r += c(ctx_3.status, ctx_3);
              r += '</span></div><h5 class="mb-0 color-green-dark">';
              r += c(ctx_3.data, ctx_3);
              r += '</h5></div></div></a><div class="divider mb-0 mt-n2"></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="col-100 text-align-center font-12">Nenhuma reserva no histórico.</div>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    