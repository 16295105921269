
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '1083b09f07',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page page-home" data-name=home><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link panel-open" data-panel=left><i class="fal fa-bars"></i></a></div><div class=title><div class=logo-small><h5 class=nome-administradora></h5></div></div><div class=right><a href=/inicial/ class=link><i class="fal fa-home"></i></a></div></div></div><div class="page-content pl-2 pr-2">';
      r += Template7Helpers.each.call(ctx_1, ctx_1.condominios, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="card shadow-sm mb-4 mt-3"><div class=card-body><div class="row mb-0"><div class=col><h3 class="title font-16">Autorizados ';
          r += c(ctx_2.nome, ctx_2);
          r += '</h3></div></div></div><div class="card border-0"><div class="card-body pt-0 mt-0"><div class="list media-list"><ul>';
          r += Template7Helpers.each.call(ctx_2, ctx_2.prestadores, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><a class="item-link item-content" href=/prestador/';
              r += c(ctx_3.idprestador, ctx_3);
              r += '/ ><div class=item-inner><div class=item-title-row><div class=item-title>';
              r += c(ctx_3.tipo, ctx_3);
              r += '</div></div><div class=item-text style=font-size:12px;>';
              r += c(ctx_3.fornecedor, ctx_3);
              r += '</div></div></a></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</ul></div></div></div></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    