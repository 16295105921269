export default {
  id: '91e69297e2',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="popup autorizado"><div class=view><div class="page no-swipeback" data-name=grid><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title>Prestadores Autorizados</div><div class=right><!-- Link to close popup --> <a class="link popup-close iconeX"><i class="fal fa-times"></i></a></div></div></div><div class="page-content pl-2 pr-2"><div class="card shadow-sm mb-4 conteudo"><div class="card-body mb-0"><div class=content><h3 class="title m-0 p-0 font-16">';
      r += c(ctx_1.prestador.tipo, ctx_1);
      r += '<br><small>';
      r += c(ctx_1.prestador.condominio, ctx_1);
      r += '</small></h3><h5><small>';
      r += c(ctx_1.prestador.fornecedor, ctx_1);
      r += '</small></h5><div class=mt-2><a class="link external" target=_system href=mailto:';
      r += c(ctx_1.prestador.email, ctx_1);
      r += '>';
      r += c(ctx_1.prestador.email, ctx_1);
      r += '</a></div><div class=mt-2><a class="link external" target=_system href=tel:';
      r += c(ctx_1.prestador.telefone_numeros, ctx_1);
      r += '>';
      r += c(ctx_1.prestador.telefone, ctx_1);
      r += '</a></div></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};