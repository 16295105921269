
import LoginPage from '../pages/login.f7.html';
import EsqueciPage from '../pages/esqueci.f7.html';
import IndexPage from '../pages/index.f7.html';
import InicialPage from '../pages/inicial.f7.html';
import PoliticaPrivacidadePage from '../pages/politica.f7.html';
import MeusDadosPage from '../pages/meus_dados.f7.html';
import MinhaSenhaPage from '../pages/senha.f7.html';
import LogoutPage from '../pages/logout.f7.html';
import NotFoundPage from '../pages/404.f7.html';


import AvisosPage from '../pages/avisos.f7.html';
import ReservasPage from '../pages/reservas.f7.html';
import Reserva1Page from '../pages/reserva1.f7.html';
import Reserva2Page from '../pages/reserva2.f7.html';
import ReservaPage from '../pages/reserva.f7.html';


import RegrasPage from '../pages/regras.f7.html';
import AvisoPage from '../pages/aviso.f7.html';
import RegraPage from '../pages/regra.f7.html';
import FaturasPage from '../pages/faturas.f7.html';
import FaturaPage from '../pages/fatura.f7.html';
import PrestadoresPage from '../pages/prestadores.f7.html';
import PrestadorPage from '../pages/prestador.f7.html';

import ChamadosPage from '../pages/chamados.f7.html';
import ChamadoPage from '../pages/chamado.f7.html';

import AssembleiasPage from '../pages/assembleias.f7.html';
import AssembleiaPage from '../pages/assembleia.f7.html';

import ContatoPage from '../pages/contato.f7.html';

import $$ from 'dom7';
import $ from 'jquery';

var routes = [
  {
    path: '/',
    component: IndexPage,
    on: {
        pageAfterIn: function test (e, page) {
          var token = this.app.data.db.getItem(this.app.id+".token");

          if(token != undefined)  {
          var usuario = JSON.parse(this.app.data.db.getItem(this.app.id+".usuario"));
            this.app.views.main.router.navigate('/inicial/', {reloadCurrent: true,ignoreCache: true}); 
           // this.app.views.main.router.navigate('/fatura/D276AECC231941F7A1276E9BB76DCCE4/', {reloadCurrent: true,ignoreCache: true}); 



          }
          else {
            this.app.views.main.router.navigate('/login/', {reloadCurrent: true,ignoreCache: true}); 
          }

        },
        pageInit: function test (e, page) {
          this.app.methods.ativarMascaras();
        }
      }    
  },  
  {
    path: '/login/',
    component: LoginPage,
       on: {
        pageInit: function test (e, page) {
          this.app.methods.ativarMascaras();
        }
      } 
  },
    {
    path: '/esqueci/',
    component: EsqueciPage,
       on: {
        pageInit: function test (e, page) {
          this.app.methods.ativarMascaras();
        }
      } 
  },

  {
    path: '/home/atualizar/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      setTimeout(function() {
        app.views.main.router.navigate('/home/', {reloadCurrent: true,ignoreCache: true});

      }, 100);
      return true;
    },
  }, 

  {
    path: '/logout/',
    async: function (routeTo, routeFrom, resolve, reject) {  

      this.app.data.db.removeItem(this.app.id+".usuario");
      this.app.data.db.removeItem(this.app.id+".token");
      location.reload()
    },
  },
  {
    path: '/politica/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      app.preloader.show();



      var politica = "";

      app.request({
          url: app.methods.request('/politica'),
          dataType: 'json',
          method: 'POST',
          cache: false,
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
            app.preloader.hide();
            resolve({
              component: PoliticaPrivacidadePage
            },{
              context: {
                "idtermo":data.politica.idtermo,
                "titulo":data.politica.titulo,
                "texto":data.politica.texto,
                "data_publicacao":data.politica.data_publicacao,
                "link":data.politica.link,
                "checksum":data.politica.checksum,
                "texto_botao":data.politica.texto_botao,

                
              }
            });
            
          }
      });
      return true;
    },
  }, 
      {
    path: '/meusDados/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      app.preloader.show();
      var politica = "";

      app.request({
          url: app.methods.request('/meusDados'),
          dataType: 'json',
          method: 'POST',
          cache: false,
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
            app.preloader.hide();
            if(data.success == true) {
              console.log(data.dados)
              resolve({
                component: MeusDadosPage
              },{
                context: {
                  "idusuario":data.dados.idusuario,
                  "nome":data.dados.nome,
                  "cpf":data.dados.cpf,
                  "celular": ''+data.dados.celular+'',
                  "email":data.dados.email,

                }
              }); 
              setTimeout(function() {
                app.methods.ativarMascaras();

              }, 100);             
            }

            
          }
      });
      return true;
    },
  }, 
  {
    path: '/minhaSenha/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      app.preloader.show();



      var politica = "";

      app.request({
          url: app.methods.request('/minhaSenha'),
          dataType: 'json',
          method: 'POST',
          cache: false,
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
            app.preloader.hide();
            if(data.success == true) {
              console.log(data.dados)
              resolve({
                component: MinhaSenhaPage
              },{
                context: {
                  "idusuario":data.dados.idusuario,
                  "nome":data.dados.nome,
                  "cpf":data.dados.cpf,
                  "celular": ''+data.dados.celular+'',
                  "email":data.dados.email,

                }
              }); 
              setTimeout(function() {
                app.methods.ativarMascaras();

              }, 100);             
            }

            
          }
      });
      return true;
    },
  },
  {
    path: '/excluirConta/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      //app.preloader.show();


      resolve({
          component: ExcluirContaPage
        },{
          context: {}
        }); 
      return true;
    },
  }, 







  {
    path: '/inicial/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      app.preloader.show();

      app.request({
          url: app.methods.request('/inicial'),
          dataType: 'json',
          method: 'POST',
          cache: false,
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
            app.preloader.hide();
            if(data.success == true) {
              resolve({
                component: InicialPage
              },{
                context: {
                  "avisos":data.avisos
                }
              });
              app.preloader.hide();

          
            }
            else {
              app.views.main.router.navigate('/logout/', {reloadCurrent: true,ignoreCache: true});
            }


            
          }
      });

      

      return true;
    },
  }, 
  {
    path: '/avisos/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      app.preloader.show();

      app.request({
          url: app.methods.request('/avisos'),
          dataType: 'json',
          method: 'POST',
          cache: false,
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
            app.preloader.hide();
            if(data.success == true) {
              resolve({
                component: AvisosPage
              },{
                context: {
                  "avisos":data.avisos,
                  "offset":data.offset,
                  "limit":data.limit
                }
              });
              
            }
            else {
              app.views.main.router.navigate('/logout/', {reloadCurrent: true,ignoreCache: true});
            }
          }
      });

      app.preloader.hide();

      return true;
    },
  }, 
  {
    path: '/reservas/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      app.preloader.show();


      app.request({
          url: app.methods.request('/reservas'),
          dataType: 'json',
          method: 'POST',
          cache: false,
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
             app.preloader.hide();
            if(data.success == true) {
              resolve({
                component: ReservasPage
              },{
                context: {
                  "reservas_existe": data.reservas.length > 0 ? true : false,
                  "reservas":data.reservas,
                  "historico_existe": data.historico.length > 0 ? true : false,
                  "historico":data.historico
                }
              });
              app.preloader.hide();
            }
            else {
              app.views.main.router.navigate('/logout/', {reloadCurrent: true,ignoreCache: true});
            }
          }
      });

     

      return true;
    },
  },   
  {
    path: '/reserva/passo1/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      app.preloader.show();


      app.request({
          url: app.methods.request('/reservaPasso1'),
          dataType: 'json',
          method: 'POST',
          cache: false,
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
            app.preloader.hide();
            if(data.success == true) {
              resolve({
                component: Reserva1Page
              },{
                context: {
                  "dependencias_existe": data.dependencias.length > 0 ? true : false,
                  "dependencias":data.dependencias
                }
              });
              app.preloader.hide();
            }
            else {
              app.views.main.router.navigate('/logout/', {reloadCurrent: true,ignoreCache: true});
            }
          }
      });

      

      return true;
    },
  }, 
    {
    path: '/reserva/passo2/:iddependencia/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      app.preloader.show();
      var iddependencia = routeTo.params.iddependencia;


      app.request({
          url: app.methods.request('/reservaPasso2'),
          dataType: 'json',
          method: 'POST',
          data:{"iddependencia":iddependencia},
          cache: false,
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
            app.preloader.hide();
            if(data.success == true) {
              resolve({
                component: Reserva2Page
              },{
                context: {
                  "horarios_existe": data.horarios.length > 0 ? true : false,
                  "horarios":data.horarios,
                  "dependencia":data.dependencia,
                  "condominio":data.condominio

                }
              });

            var diasDesabilitados = [];
            $.each(data.reservas,function(k,reserva){
              diasDesabilitados.push(new Date(Date.parse(reserva.data_iso + " GMT-0300")).toISOString());
            });



            setTimeout(function() {
              console.log(diasDesabilitados);
            // Inline with custom toolbar
                  var monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
                  var calendarInline = app.calendar.create({
                    containerEl: '#demo-calendar-inline-container',
                    minDate: data.hoje,
                    disabled: function(date){
                      //console.log(new Date(date).toISOString());
                      console.log(diasDesabilitados.indexOf(date.toISOString()));
                      return data.dias.indexOf(date.getDay()) >= 0 ||  diasDesabilitados.indexOf(date.toISOString()) >= 0;
                    },
                    value: [new Date()],
                    renderToolbar: function () {
                      return '<div class="toolbar calendar-custom-toolbar no-shadow">' +
                        '<div class="toolbar-inner">' +
                        '<div class="left">' +
                        '<a  class="link icon-only"><i class="icon icon-back"></i></a>' +
                        '</div>' +
                        '<div class="center"></div>' +
                        '<div class="right">' +
                        '<a  class="link icon-only"><i class="icon icon-forward"></i></a>' +
                        '</div>' +
                        '</div>' +
                        '</div>';
                    },
                    on: {
                      init: function (c) {
                        $('.calendar-custom-toolbar .center').text(monthNames[c.currentMonth] + ', ' + c.currentYear);
                        $('.calendar-custom-toolbar .left .link').on('click', function () {
                          calendarInline.prevMonth();
                        });
                        $('.calendar-custom-toolbar .right .link').on('click', function () {
                          calendarInline.nextMonth();
                        });
                      },
                      monthYearChangeStart: function (c) {
                        $('.calendar-custom-toolbar .center').text(monthNames[c.currentMonth] + ', ' + c.currentYear);
                      },
                      dayClick:function(calendar, dayEl, year, month, day){
                        var data = new Date(year,month,day);
                        var data_atual = new Date();

                        
                        var dataSelecionada = data.toISOString().split("T")[0];
                        var dataSelecionada2 = data.toLocaleDateString().split("T")[0];


                        app.request({
                            url: app.methods.request('/reservaPasso3'),
                            dataType: 'json',
                            method: 'POST',
                            cache: false,
                            data:{
                              "iddependencia":iddependencia,
                              "data_atual":data_atual.toISOString('pt-BR',{ timeZone: 'America/Sao_Paulo' }),
                              "dataSelecionada":dataSelecionada,
                              "dataSelecionada2":dataSelecionada2,
                            },
                            crossDomain: true,
                            error: app.methods.errorFnc,
                            success: function (data) {
                              app.preloader.hide();
                              if(data.success == true) {
                                $(".selecione-horario").removeClass("hidden");
                                $(".data-escolhida").html(dataSelecionada2);
                                $(".btnReservarDependencia").attr("data-data",dataSelecionada);

                                $('.aviso-chaves').html(data.mensagem)
                                app.preloader.hide();
                              }
                              else {
                                $(".selecione-horario").addClass("hidden");

                                app.dialog.create({
                                title: 'Ops!',
                                text: data.mensagem,
                                closeByBackdropClick: 'true',
                                buttons: [
                                    
                                    {
                                        text: 'Entendi',
                                        
                                    },
                                ],
                            }).open();

                               
                              }
                            }
                        });


                        console.log(data);

                        
                      }
                    }
                  });
            }, 500);

              
            }
            else {
              app.views.main.router.navigate('/logout/', {reloadCurrent: true,ignoreCache: true});
            }
          }
      });

      app.preloader.hide();

      return true;
    },
  },
  {
    path: '/reserva/:codigo/:acao/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      app.preloader.show();
      var codigo = routeTo.params.codigo;
      var acao = String(routeTo.params.acao);

      app.request({
          url: app.methods.request('/reserva'),
          dataType: 'json',
          method: 'POST',
          data:{"codigo":codigo},
          cache: false,
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
            app.preloader.hide();
            if(data.success == true) {
              resolve({
                    popup: {
                        component: ReservaPage
                    }
                },{
                context: {
                  "confirmacao": acao == "confirmacao" > 0 ? true : false,
                  "titulo": acao == "confirmacao" > 0 ? "Confirmação de Reserva" : "Informações da Reserva",
                  "reserva":data.reserva,
                }
              });
            

              
            }
            else {
              app.views.main.router.navigate('/logout/', {reloadCurrent: true,ignoreCache: true});
            }
          }
      });

      app.preloader.hide();

      return true;
    },
  }, 

  {
    path: '/aviso/:idaviso_pessoa/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      var idaviso_pessoa = routeTo.params.idaviso_pessoa;

      app.preloader.show();

      app.request({
          url: app.methods.request('/aviso'),
          dataType: 'json',
          method: 'POST',
          cache: false,
          data:{
            "idaviso_pessoa":idaviso_pessoa
          },
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
            if(data.success == true) {
              resolve({
                popup: {
                  component: AvisoPage
                }
              },{
                context: {
                    "aviso":data.aviso,
                  }
              }); 
              app.preloader.hide();

          
            }
            else {
              app.views.main.router.navigate('/logout/', {reloadCurrent: true,ignoreCache: true});
            }


            
          }
      });
         



    },
  }, 
  {
    path: '/faturas/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      app.preloader.show();

      app.request({
          url: app.methods.request('/faturas'),
          dataType: 'json',
          method: 'POST',
          cache: false,
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
            if(data.success == true) {
              resolve({
                component: FaturasPage
              },{
                context: {
                  "pendentes":data.pendentes,
                  "pendentes_existe":data.pendentes.length > 0 ? true : false,
                  "pagas_existe":data.pagas.length > 0 ? true : false,
                  "pagas":data.pagas,
                  "data":data.data,
                }
              });
              app.preloader.hide();

          
            }
            else {
              app.views.main.router.navigate('/logout/', {reloadCurrent: true,ignoreCache: true});
            }


            
          }
      });

      app.preloader.hide();

      return true;
    },
  }, 
  {
    path: '/fatura/:invoice_id/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      var invoice_id = routeTo.params.invoice_id;

      app.preloader.show();

      app.request({
          url: app.methods.request('/fatura'),
          dataType: 'json',
          method: 'POST',
          cache: false,
          data:{
            "invoice_id":invoice_id
          },
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
            if(data.success == true) {
              resolve({
                popup: {
                  component: FaturaPage
                }
              },{
                context: {
                    "fatura":data.fatura,
                    "vencimento":data.vencimento,
                  }
              });

              console.log(data);

              setTimeout(function() {
                var calendarModal = app.calendar.create({
                  locale : 'pt-BR',
                  inputEl: '#dataSegundaVia',
                  openIn: 'customModal',
                  header: false,
                  footer: false,
                  closeOnSelect: true,
                  minDate: new Date(data.vencimento),
                  maxDate: new Date(data.vencimentoFinal),
                });                
              }, 100); 
              app.preloader.hide();
            }
            else {
              //app.views.main.router.navigate('/logout/', {reloadCurrent: true,ignoreCache: true});
            }

          }
      });

    },
  }, 
  {
    path: '/regras/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      app.preloader.show();


      app.request({
          url: app.methods.request('/regras'),
          dataType: 'json',
          method: 'POST',
          cache: false,
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
            if(data.success == true) {
              resolve({
                component: RegrasPage
              },{
                context: {
                  "condominios":data.condominios,
                }
              });
              app.preloader.hide();
            }
            else {
              app.views.main.router.navigate('/logout/', {reloadCurrent: true,ignoreCache: true});
            }
          }
      });

      app.preloader.hide();

      return true;
    },
  }, 
  {
      path: '/regra/:idregra/',
      async: function (routeTo, routeFrom, resolve, reject) {
        var app = this.app;
        var idregra = routeTo.params.idregra;

        app.preloader.show();

        app.request({
            url: app.methods.request('/regra'),
            dataType: 'json',
            method: 'POST',
            cache: false,
            data:{
              "idregra":idregra
            },
            crossDomain: true,
            error: app.methods.errorFnc,
            success: function (data) {
              if(data.success == true) {
                resolve({
                  popup: {
                    component: RegraPage
                  }
                },{
                  context: {
                      "regra":data.regra,
                    }
                }); 
                app.preloader.hide();

            
              }
              else {
                app.views.main.router.navigate('/logout/', {reloadCurrent: true,ignoreCache: true});
              }


              
            }
        });
      },
  },


  {
    path: '/prestadores/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      app.preloader.show();
      app.request({
          url: app.methods.request('/prestadores'),
          dataType: 'json',
          method: 'POST',
          cache: false,
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
            if(data.success == true) {
              resolve({
                component: PrestadoresPage
              },{
                context: {
                  "condominios":data.condominios,
                }
              });
              app.preloader.hide();
            }
            else {
              app.views.main.router.navigate('/logout/', {reloadCurrent: true,ignoreCache: true});
            }
          }
      });
      app.preloader.hide();
      return true;
    },
  }, 
  {
      path: '/prestador/:idprestador/',
      async: function (routeTo, routeFrom, resolve, reject) {
        var app = this.app;
        var idprestador = routeTo.params.idprestador;
        app.preloader.show();
        app.request({
            url: app.methods.request('/prestador'),
            dataType: 'json',
            method: 'POST',
            cache: false,
            data:{
              "idprestador":idprestador
            },
            crossDomain: true,
            error: app.methods.errorFnc,
            success: function (data) {
              if(data.success == true) {
                resolve({
                  popup: {
                    component: PrestadorPage
                  }
                },{
                  context: {
                      "prestador":data.prestador,
                    }
                }); 
                app.preloader.hide();

            
              }
              else {
                app.views.main.router.navigate('/logout/', {reloadCurrent: true,ignoreCache: true});
              }


              
            }
        });
      },
  },
  {
    path: '/chamados/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      app.preloader.show();


      app.request({
          url: app.methods.request('/chamados'),
          dataType: 'json',
          method: 'POST',
          cache: false,
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
             app.preloader.hide();
            if(data.success == true) {
              resolve({
                component: ChamadosPage
              },{
                context: {
                  "chamados_existe": data.chamados.length > 0 ? true : false,
                  "chamados":data.chamados
                }
              });
              app.preloader.hide();
            }
            else {
              app.views.main.router.navigate('/logout/', {reloadCurrent: true,ignoreCache: true});
            }
          }
      });

     

      return true;
    },
  },
  {
    path: '/chamado/:idchamado/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      var idchamado = routeTo.params.idchamado;

      app.preloader.show();

      app.request({
          url: app.methods.request('/chamado'),
          dataType: 'json',
          method: 'POST',
          cache: false,
          data:{
            "idchamado":idchamado
          },
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
            if(data.success == true) {
              resolve({
                popup: {
                  component: ChamadoPage
                }
              },{
                context: {
                    "chamado":data.chamado,
                  }
              }); 
              app.preloader.hide();

          
            }
            else {
              app.views.main.router.navigate('/logout/', {reloadCurrent: true,ignoreCache: true});
            }


            
          }
      });
         



    },
  }, 

  {
    path: '/assembleias/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      app.preloader.show();

      app.request({
          url: app.methods.request('/assembleias'),
          dataType: 'json',
          method: 'POST',
          cache: false,
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
            if(data.success == true) {
              resolve({
                component: AssembleiasPage
              },{
                context: {
                  "pendentes":data.pendentes,
                  "pendentes_existe":data.pendentes.length > 0 ? true : false,
                  "realizadas_existe":data.realizadas.length > 0 ? true : false,
                  "realizadas":data.realizadas,
                  "data":data.data,
                }
              });
              app.preloader.hide();

          
            }
            else {
              app.views.main.router.navigate('/logout/', {reloadCurrent: true,ignoreCache: true});
            }


            
          }
      });

      app.preloader.hide();

      return true;
    },
  }, 
  {
    path: '/assembleia/:idassembleia/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      var idassembleia = routeTo.params.idassembleia;

      app.preloader.show();

      app.request({
          url: app.methods.request('/assembleia'),
          dataType: 'json',
          method: 'POST',
          cache: false,
          data:{
            "idassembleia":idassembleia
          },
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
            if(data.success == true) {
              resolve({
                popup: {
                  component: AssembleiaPage
                }
              },{
                context: {
                    "assembleia":data.assembleia,
                  }
              });
              app.preloader.hide();
            }
            else {
              //app.views.main.router.navigate('/logout/', {reloadCurrent: true,ignoreCache: true});
            }

          }
      });

    },
  },  

  {
    path: '/contato/',
    async: function (routeTo, routeFrom, resolve, reject) {
      var app = this.app;
      app.preloader.show();

      app.request({
          url: app.methods.request('/contato'),
          dataType: 'json',
          method: 'POST',
          cache: false,
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
            if(data.success == true) {
              resolve({
                component: ContatoPage
              },{
                context: {
                  "contato":data.contato
                }
              });
              app.preloader.hide();

          
            }
            else {
              app.views.main.router.navigate('/logout/', {reloadCurrent: true,ignoreCache: true});
            }


            
          }
      });

      app.preloader.hide();

      return true;
    },
  },   


  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;

/*
- Tempo Parado - OK
- Distância Percorrida Por dia - OK
- Inicio de Trabalho Diário/Término - OK
- Relatório de Manutenção - OK
- Relatório de Horímetro - OK
- Relatório de Carga e Descarga
*/