export default {
  id: '9d29f1671d',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page page-home" data-name=home><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link panel-open" data-panel=left><i class="fal fa-bars"></i></a></div><div class=title><div class=logo-small><h5 class=nome-administradora></h5></div></div><div class=right><a href=/inicial/ class=link><i class="fal fa-home"></i></a></div></div></div><div class="page-content pl-2 pr-2"><div class="card card-style"><div class=content><h4 class="font-18 color-orange-dark">';
      r += c(ctx_1.titulo, ctx_1);
      r += '</h4><div><small><b>Publicado em ';
      r += c(ctx_1.data_publicacao, ctx_1);
      r += '.</b></small></div><div><small>Código de Validação: ';
      r += c(ctx_1.checksum, ctx_1);
      r += '</small></div><div>';
      r += c(ctx_1.texto_resumo, ctx_1);
      r += '</div><div>';
      r += c(ctx_1.texto, ctx_1);
      r += '</div><div style="text-align: center;"><a href=';
      r += c(ctx_1.link, ctx_1);
      r += ' class=external target=_system>';
      r += c(ctx_1.texto_botao, ctx_1);
      r += '</a></div></div></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};