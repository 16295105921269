
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '9f85310e55',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page no-swipeback" data-name=sobre><div class="navbar no-hairline"><div class=navbar-bg></div><div class="navbar-inner sliding text-align-center"><div class=left></div><div class=title>Meus Dados</div><div class=right></div></div></div><div class="toolbar toolbar-bottom"><div class=toolbar-inner><a href=/mapa/ class=link><i class="fas fa-2x fa-map-marked-alt"><span>MAPA</span></i></a> <a href=/grid/ class=link><i class="fas fa-2x fa-th"><span>GRID</span></i></a> <a href=/distancia/ class=link><i class="fas fa-2x fa-drafting-compass"><span>ROTA</span></i></a> <a href=/excecoes/ class=link><i class="fas fa-2x fa-exclamation-triangle"><span>EXCEÇÕES</span></i></a> <a href=# class="link panel-open link-active" data-panel=right><i class="fas fa-2x fa-ellipsis-h"><span>INFOS</span></i></a></div></div><div class="page-content hide-navbar-on-scroll" style="background-color: #FFF;"><div class=card><div class="list mt-0 p-2"><form id=formCadastro><ul><li><a class=item><div class=item-content><div class=item-inner><div class=item-title>Nome do Usuário</div></div></div><input type=text name=Usuario[nome] value="';
      r += Template7Helpers.escape.call(ctx_1, ctx_1.nome, {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '"></a></li><li><a class=item><div class=item-content><div class=item-inner><div class=item-title>E-mail</div></div></div><input type=text name=Usuario[email] value="';
      r += Template7Helpers.escape.call(ctx_1, ctx_1.email, {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '"></a></li><li><a class=item><div class=item-content><div class=item-inner><div class=item-title>CPF</div></div></div><input type=text name=Usuario[cpf] value="';
      r += Template7Helpers.escape.call(ctx_1, ctx_1.cpf, {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" class=cpf></a></li><li><a class=item><div class=item-content><div class=item-inner><div class=item-title>Celular</div></div></div><input type=text name=Usuario[celular] value="';
      r += Template7Helpers.escape.call(ctx_1, ctx_1.celular, {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" class=celular></a></li><li class="item text-align-center"><button class="button button-round button-fill color-red btnSalvarCadastro mt-2 mb-4" type=button><small>SALVAR MEUS DADOS</small></button></li><li><span><b>Lembre-se: o CPF e/ou o e-mail são as informações chaves para você acessar o Aplicativo e o sistema da Assemilsat. Cuidado ao modifica-los.</b></span></li><li><hr></li><li><div class="block text-align-center"><div class=col><a href=/minhaSenha/ class="link mt-3 mb-3" href=#><small>Alterar senha</small></a></div></div></li><li><hr></li><li><div class="block text-align-center"><div class=col><a href=/excluirConta/ class="link mt-3 mb-3" href=#><small>Solicitar Exclusão da minha conta e anonimização dos meus dados pessoais.</small></a></div></div></li></ul></form></div></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    