
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '0cadb78a64',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page page-home" data-name=home><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link panel-open" data-panel=left><i class="fal fa-bars"></i></a></div><div class=title><div class=logo-small><h5 class=nome-administradora></h5></div></div><div class=right><a href=/inicial/ class=link><i class="fal fa-home"></i></a></div></div></div><div class="row m-0 p-0 mt-5"><div class="col-100 mt-2"><div class="toolbar tabbar margin-bottom template"><div class=toolbar-inner><a href=#tab-pagar class="tab-link tab-link-active">Em Andamento</a> <a href=#tab-pagas class=tab-link>Realizadas</a></div></div></div></div><div class="page-content pl-2 pr-2 pt-1"><div class=tabs><div id=tab-pagar class="tab tab-active" style="padding-bottom: 150px;"><div class="list media-list p-0 m-0"><ul>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.pendentes_existe, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.pendentes, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><a class="item-link item-content" href=/fatura/';
              r += c(ctx_3.invoice_id, ctx_3);
              r += '/ ><div class=item-inner><div class=item-title-row><div class=item-title><span class="tag text-white bg-';
              r += c(ctx_3.status_class, ctx_3);
              r += ' border-';
              r += c(ctx_3.status_class, ctx_3);
              r += ' py-1 px-2 float-end mt-1"><small>';
              r += c(ctx_3.status, ctx_3);
              r += '</small></span></div><div class=item-after>';
              r += c(ctx_3.vencimento, ctx_3);
              r += '</div></div><div class=item-subtitle>Valor a pagar: R$ ';
              r += c(ctx_3.valor, ctx_3);
              r += '</div><div class=item-text><div>';
              r += c(ctx_3.fatura, ctx_3);
              r += ' - ';
              r += c(ctx_3.descricao, ctx_3);
              r += '</div>';
              r += Template7Helpers.if.call(ctx_3, ctx_3.expirada, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class=text-align-center><small>Clique para gerar a 2ª via.</small></div>';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += '</div></div></a></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<li class=text-align-center><small>Nenhuma assembleia em andamento.<br>';
          r += c(ctx_2.data, ctx_2);
          r += '</small></li>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div><div id=tab-pagas class=tab><div class="list media-list p-0 m-0" style="padding-bottom: 150px;"><ul>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.pagas_existe, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.pagas, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><a class="item-link item-content" href=/fatura/';
              r += c(ctx_3.invoice_id, ctx_3);
              r += '/ ><div class=item-inner><div class=item-title-row><div class=item-title><span class="tag text-white bg-';
              r += c(ctx_3.status_class, ctx_3);
              r += ' border-';
              r += c(ctx_3.status_class, ctx_3);
              r += ' py-1 px-2 float-end mt-1"><small>';
              r += c(ctx_3.status, ctx_3);
              r += '</small></span></div><div class=item-after>Data de Pagamento: ';
              r += c(ctx_3.recebimento, ctx_3);
              r += '</div></div><div class=item-subtitle>Valor Pagamento: R$ ';
              r += c(ctx_3.valor, ctx_3);
              r += '</div><div class=item-text>';
              r += c(ctx_3.fatura, ctx_3);
              r += ' - ';
              r += c(ctx_3.descricao, ctx_3);
              r += '</div></div></a></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<li class=text-align-center><small>Nenhuma assembleia concluída.<br>';
          r += c(ctx_2.data, ctx_2);
          r += '</small></li>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    