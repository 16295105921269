
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '049503ba5d',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page page-home" data-name=home><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link panel-open" data-panel=left><i class="fal fa-bars"></i></a></div><div class=title><div class=logo-small><h5 class="mb-0 pb-0">Faça sua Reserva</h5></div></div><div class=right><a href=/inicial/ class=link><i class="fal fa-home"></i></a></div></div></div><div class="page-content pl-2 pr-2"><div class="card card-style mb-1"><div class="content mt-3 pb-0 mb-0"><h4 class="mb-1 font-14 font-600 color-orange-dark">';
      r += c(root.dependencia.nome, ctx_1);
      r += '</h4><h5 class=mb-1>1. Selecione uma Data</h5></div><div id=demo-calendar-inline-container></div></div><div class="card card-style selecione-horario hidden"><div class="content mt-3 pb-0 mb-0"><h5 class=mb-0>2. Selecione uma Opção</h5></div><div class="divider mb-0"></div>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.horarios_existe, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.horarios, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <a href=# class="content btnReservarDependencia" data-condominio=';
              r += c(root.condominio.idcondominio, ctx_3);
              r += ' data-horario=';
              r += c(ctx_3.iddependencia_horario, ctx_3);
              r += ' data-dependencia=';
              r += c(ctx_3.iddependencia, ctx_3);
              r += '><div class=d-flex><div class=ps-1><h6 class="font-600 font-12 opacity-50 mb-0">';
              r += c(root.condominio.nome_fantasia, ctx_3);
              r += '</h6><h4 class=mb-1>';
              r += c(root.dependencia.nome, ctx_3);
              r += '</h4><h6 class="font-600 font-12 opacity-50 mb-0"><span class=data-escolhida></span> - ';
              r += c(ctx_3.horario, ctx_3);
              r += '</h6></div><div class="ms-auto align-self-center text-center pe-1"><div class="ms-auto align-self-center"><span class="badge bg-blue-dark font-14">R$ ';
              r += c(root.dependencia.taxa_reserva, ctx_3);
              r += '</span></div><h5 class="mb-0 color-orange-dark font-12 font-600">Cancelamento</h5><span class="color-orange-dark font-12 font-400">';
              r += c(root.dependencia.cancelamento, ctx_3);
              r += '</span></div></div><div class="divider mb-1 mt-1"></div><p class="aviso-chaves font-12 font-400 color-red-dark line-height-xs text-center"></p><div class="divider mb-1 mt-1"></div><div class=text-align-center><small class="btn btn-xs bg-orange-dark">Clique para reservar</small></div></a><div class="divider mb-1 mt-1"></div>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=col-100>Nenhuma reserva realizada até o momento.</div>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    