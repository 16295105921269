
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: 'ea1bac9613',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page page-home" data-name=home><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link panel-open" data-panel=left><i class="fal fa-bars"></i></a></div><div class=title><div class=logo-small><h5 class=nome-administradora></h5></div></div><div class=right><a href=/inicial/ class=link><i class="fal fa-home"></i></a></div></div></div><div class="page-content pl-2 pr-2"><div class=content><div class="list media-list m-0 p-0"><ul class=chamados>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.chamados_existe, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          r += Template7Helpers.each.call(ctx_2, ctx_2.chamados, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '<li><div class="card card-style mb-2"><div class=content><div><h4 class=font-16><i class="fal ';
              r += c(ctx_3.tipo_icone, ctx_3);
              r += '"></i> ';
              r += c(ctx_3.tipo, ctx_3);
              r += ' - ';
              r += c(ctx_3.assunto, ctx_3);
              r += '</h4></div><div class="divider mb-2 mt-2"></div><h5 class="font-12 font-500 pb-2 pt-2 mb-0"><i class="fal fa-calendar-alt font-12 color-blue-dark pe-1"></i> Data da Solicitação: ';
              r += c(ctx_3.data_ocorrencia, ctx_3);
              r += '</h5><div class=d-flex><div><span class="badge ';
              r += c(ctx_3.cor, ctx_3);
              r += ' rounded-xl font-10">';
              r += c(ctx_3.status, ctx_3);
              r += '</span></div><div class=ms-auto><span class="badge ';
              r += c(ctx_3.cor_prioridade, ctx_3);
              r += ' rounded-xl font-10">Prioridade: ';
              r += c(ctx_3.urgente, ctx_3);
              r += '</span></div></div><div class="divider mb-2 mt-2"></div><div class=d-flex><div><h6 class="font-400 color-theme opacity-50 font-11 pt-1">Responsável</h6><h5 class="font-12 font-500 pb-2 pt-2 mb-0"><i class="fal fa-check-circle font-12 color-blue-dark pe-1"></i> ';
              r += c(ctx_3.responsavel, ctx_3);
              r += '</h5></div><div class="ms-auto text-center"><h6 class="font-500 font-11 opacity-50 pt-1 mb-0">interações</h6><h5 class="font-20 mb-1 color-green-dark pt-1">';
              r += c(ctx_3.interacoes, ctx_3);
              r += '</h5></div></div><div class="divider m-0"></div><div class=text-align-center><a href=/chamado/';
              r += c(ctx_3.idchamado, ctx_3);
              r += '/ class="text-uppercase font-12 m-0"><i class="fal fa-search"></i> Visualizar Chamado</a></div></div></div></li>';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '<div class="col-100 text-align-center font-12">Nenhum chamado encontrado.</div>';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    