import $$ from 'dom7';
import Framework7 from 'framework7/framework7.esm.bundle.js';
import $ from 'jquery';

import mask from 'jquery-mask-plugin';

// Import F7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';
import '../css/style3.css';
import '../css/style.css';
import '../css/style2.css';

import '../css/fontawesome/css/combined.min.css';
import '../css/flaticon_mycollection.css';
import '../css/bootstrap.css';




import '../css/fontes.css';
import '../css/margens.css';

// Import Cordova APIs
import cordovaApp from './cordova-app.js';
// Import Routes
import routes from './routes.js';
import CryptoJS from '../js/jquery.md5.js';
import fnc from '../js/fncs.js';

Date.prototype.addDays = function(days) {
    this.setDate(this.getDate() + parseInt(days));
    return this;
};

Framework7.use(CryptoJS);


// Import main app component
import App from '../app.f7.html';

var app = new Framework7({
  root: '#app', // App root element
  component: App, // App main component
  id: 'br.com.mobwe.umob', // App bundle ID
  name: 'uMOB', // App name
  theme: 'md', // Automatic theme detection
  data: function () {
    return {
      versao: "1.0.0",
      db: window.localStorage,
      baseUrl: baseUrl,
      mapa: null,
      veiculo: null,
      rotaAtiva: null,   
      localizacao: {},

    };
  },
  smartSelect: {
    pageTitle: 'Selecione',
    openIn: 'popup',
  }, 

  methods: {
    onBackKeyDown: function() {
            var leftp = app.panel.left && app.panel.left.opened;
            var rightp = app.panel.right && app.panel.right.opened;

            if ( leftp || rightp ) {

                app.panel.close();
                return false;
            }else if ($$('.modal-in').length > 0) {
              
                app.dialog.close();
                app.popup.close();
                return false;
            } else if (app.views.main.router.url == '/') {
                    navigator.app.exitApp();
            } else {

                app.views.main.router.back();
           }
      },
      ativarMascaras: function () {
        var options = {
          onKeyPress : function(cpfcnpj, e, field, options) {
            var masks = ['000.000.000-000', '00.000.000/0000-00'];
            var mask = (cpfcnpj.length > 14) ? masks[1] : masks[0];
            $('.cpfcnpj').mask(mask, options);
          }
        };

        $('input.cartao').mask('0000 0000 0000 0000');
        $('input.validade').mask('00/00');
        $('input.cpfcnpj').mask('000.000.000-000', options);
        $('input.cpf').mask('000.000.000-00', {reverse: false});
        $('input.cnpj').mask('00.000.000/0000-00', {reverse: false});      
        $("input.telefone").mask("(00) 00000-0000");
        $("input.celular").mask("(00) 00000-0000");
        $("input.cpf").mask("000.000.000-00");
        $("input.codigo").mask("SSSS-0000");
        $("input.codigovip").mask("SSS-0000");
        $("input.data").mask("00/00/0000");
        $('input.moeda').mask("#.##0,00", {reverse: true});
        $('input.inteiro').mask("#", {reverse: true});
    },  
    request: function(e) {
      var url = baseUrl + e +"?token="+window.localStorage.getItem("br.com.mobwe.umob.token")+"&app=1";
      return url;
    },
    verificarLogout: function(data) {
      if(data.success == false && data.token == false) {
        app.data.db.removeItem(app.id+".usuario");
        app.data.db.removeItem(app.id+".token");
        app.data.db.removeItem(app.id+".pushservice");


        app.preloader.hide();
        location.reload();
        return;    
      }

    },
    autenticar: function() {
      var usuario = $$('#usuarioLogin').val();
      var password = $$('#senhaLogin').val();
      var senha = password;//CryptoJS.MD5('&xh1%2as$(px827%-'+password).toString();

      app.preloader.show();
        app.request({
            url: baseUrl + '/autenticar',
            dataType: 'json',
            method: 'POST',
            data: {"usuario":usuario,"senha":senha},
            cache: false,
            crossDomain: true,
            
            success: function (data) {
              app.preloader.hide();
              if(data.success == true) {
                app.data.db.setItem(app.id+".usuario", JSON.stringify(data.usuario));
                app.data.db.setItem(app.id+".empresa", JSON.stringify(data.empresa));
                app.data.db.setItem(app.id+".token", data.token);
                app.preloader.hide();
                app.views.main.router.navigate('/inicial/', {reloadCurrent: true,ignoreCache: true}); 
              }
              else {
                var notification = app.dialog.create({
                    icon: '<i class="fal fa-2x fa-exclamation-triangle"></i>',
                    title: 'Atenção',
                    text: data.erro,
                    buttons: [
                        {
                            text: 'Entendi',
                        }
                    ],
                    closeTimeout: 3000,
                    on: {
                      opened: function () {
                        
                      }
                    }
                  })
                notification.open()
                }
                
            },
            error: app.methods.errorFnc,
        });

    },

    errorFnc:function(data,status){
      app.preloader.hide();
      var notification = app.dialog.create({
        icon: '<i class="fal fa-2x fa-exclamation-triangle"></i>',
        title: "Encontramos um erro!",
        text: data.erro ? data.erro : 'Ocorreu um erro ao carregar as informações: '+JSON.stringify(status)+JSON.stringify(data.requestUrl),
        closeTimeout: 5000,
        buttons: [
            {
                text: 'Ok',
            }
        ],
        on: {
          opened: function () {
            
          }
        }
    })
    notification.open()   /* */          
    app.preloader.hide();
  },
  esqueci: function() {
      var usuario = $$('#usuarioEsqueci').val();

      app.preloader.show();
        app.request({
            url: baseUrl + '/esqueci',
            dataType: 'json',
            method: 'POST',
            data: {"usuario":usuario},
            cache: false,
            crossDomain: true,
            
            success: function (data) {
              app.preloader.hide();
              if(data.success == true) {
                $(".mensagemEsqueci").html(data.erro)
                setTimeout(function() {
                  app.views.main.router.navigate('/', {reloadCurrent: true,ignoreCache: true}); 
                }, 2000);


                var notification = app.notification.create({
                    icon: '<i class="fal fa-2x fa-check"></i>',
                    title: 'Sucesso!',
                    text: data.mensagem,
                    closeTimeout: 2000,
                    on: {
                      opened: function () {
                        
                      }
                    }
                  })
                notification.open()






              }
              else {
                var notification = app.notification.create({
                    icon: '<i class="fal fa-2x fa-exclamation-triangle"></i>',
                    title: 'Atenção',
                    text: data.erro,
                    closeTimeout: 3000,
                    on: {
                      opened: function () {
                        
                      }
                    }
                  })
                notification.open()
                }
                
            },
            error: app.methods.errorFnc,
        });

    },    
  },


  // App routes
  routes: routes,

  // Input settings
  input: {
    scrollIntoViewOnFocus: Framework7.device.cordova && !Framework7.device.electron,
    scrollIntoViewCentered: Framework7.device.cordova && !Framework7.device.electron,
  },
  // Cordova Statusbar settings
  statusbar: {
    iosOverlaysWebView: true,
    androidOverlaysWebView: false,
  },
  on: {
    init: function () {
      var f7 = this;
      //if (f7.device.cordova) {
        // Init cordova APIs (see cordova-app.js)
        cordovaApp.init(f7,app);
        
      //}
    },
  },
});

var mapaObj;

document.addEventListener("backbutton", app.methods.onBackKeyDown, false);

$$('body').on('click','.btnAutenticar', function () {
  app.methods.autenticar();
});






$$('body').off("click").on('click','.btnReservarDependencia', function () {
  
  var idcondominio = $$(this).data("condominio");
  var iddependencia = $$(this).data("dependencia");
  var iddependencia_horario = $$(this).data("horario");
  var data = $$(this).data("data");


  app.dialog.create({
                    title: 'Confirmação de Reserva',
                    text: 'Tem certeza que deseja realizar esta reserva?',
                    closeByBackdropClick: 'true',
                    buttons: [
                        {
                            text: 'Sim',
                            onClick: function () {
                                app.preloader.show();
                                app.request({
                                  url: app.methods.request('/reservarDependencia'),
                                  dataType: 'json',
                                  method: 'POST',
                                  data:{
                                    "iddependencia":iddependencia,
                                    "iddependencia_horario":iddependencia_horario,
                                    "idcondominio":idcondominio,
                                    "data":data,
                                  },
                                  cache: false,
                                  crossDomain: true,
                                  error: app.methods.errorFnc,
                                  success: function (data) {

                                  app.preloader.hide();

                                  if(data.success == true) {
                                    app.views.main.router.navigate('/reserva/'+data.reserva.codigo+'/confirmacao/', {reloadCurrent: true,ignoreCache: true}); 
                                  } else {
                                  var notification = app.notification.create({
                                          icon: '<i class="fal fa-2x fa-exclamation-triangle"></i>',
                                          title: 'Atenção!',
                                          text: data.mensagem,
                                          closeTimeout: 2000,
                                          on: {
                                            opened: function () {
                                              
                                            }
                                          }
                                        })
                                      notification.open()            
                                  }
                                  

                                  }
                              }); 
                            },
                        },
                        {
                            text: 'Não',
                            
                        },
                    ],
                }).open();

   



});





$$('body').off("click").on('click','.btnGerarSegundaFatura', function () {
  app.preloader.show();
  var vencimento = $$("#dataSegundaVia").val();
  var invoice_id = $$("#invoice_idSegundaVia").val();


  if(vencimento.length == 10) {
    app.request({
          url: app.methods.request('/segundaVia'),
          dataType: 'json',
          method: 'POST',
          data:{
            "vencimento":vencimento,
            "invoice_id":invoice_id
          },
          cache: false,
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {

          app.preloader.hide();
          var notification = app.notification.create({
                  icon: '<i class="fal fa-2x fa-check"></i>',
                  title: 'Sucesso!',
                  text: 'Segunda Via gerada com Sucesso!',
                  closeTimeout: 2000,
                  on: {
                    opened: function () {
                      
                    },
                    closed: function () {
                      app.views.main.router.navigate('/faturas/', {reloadCurrent: true,ignoreCache: true}); 
                    }
                  }
                })
              notification.open()


            
            
          }
      });    
  } else {
    var notification = app.notification.create({
        icon: '<i class="fal fa-2x fa-exclamation-triangle"></i>',
        title: 'Atenção!',
        text: 'É necessário selecionar uma data de vencimento!',
        closeTimeout: 2000,
        on: {
          opened: function () {
            
          }
        }
      })
    notification.open()
  }


});




$$('body').on('click','.btnEsqueci', function () {
  app.methods.esqueci();
});

$$(document).on('page:init', function (e) {
  // Do something here when page loaded and initialized for all pages

  /* coverimg */
  $$('.coverimg').each(function () {
    var imgpath = $$(this).find('img');
    $$(this).css('background-image', 'url(' + imgpath.attr('src') + ')');
    imgpath.hide();
  });

  $$('.accordion-toggle').on('click', function () {
    $$(this).toggleClass('active')
    $$(this).closest('.accordion-list').find('.accordion-content').toggleClass('show')
  })

  /* static footer*/
});

$$(document).on('page:afterin', function (e) {
  var empresa = JSON.parse(app.data.db.getItem(app.id+".empresa"));
  if(empresa != null && empresa != undefined && empresa != "")
    $(".nome-administradora").html(empresa.nome_fantasia);
  
  /* scroll from top and add class */
  $$('.view-main .page-current .page-content').on('scroll', function () {
    if ($$(this).scrollTop() > '10') {
      $$('.view-main .navbar-current').addClass('active');
    } else {
      $$('.view-main .navbar-current').removeClass('active');
    }
  });

  /* static footer*/
  if ($$('.page.page-current .footer').length > 0) {
    $$('.view.view-main .page-content').addClass('has-footer');
  } else {
    $$('.view.view-main .page-content').removeClass('has-footer');
  }
  $$('.centerbutton .nav-link').on('click', function () {
    $$(this).toggleClass('active')
  })

});

  var clipboard = new ClipboardJS('.btnCopiarCodigoBarras');

  clipboard.on('success', function(e) {
      var notification = app.notification.create({
          icon: '<i class="fal fa-2x fa-check"></i>',
          title: 'Sucesso!',
          text: "Código de Barras copiado com sucesso!",
          closeTimeout: 1000,
          on: {
            opened: function () {
              e.clearSelection();
            }
          }
        })
      notification.open()

      
  });




$$(document).on('page:init', '.page[data-name="home"]', function (e) {

  /* pwa app install */
  $$('#addtohome').on('click', function () {
    if (deferredPrompt !== undefined) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(function (choiceResult) {

        if (choiceResult.outcome == 'dismissed') {
          console.log('User cancelled home screen install');
        }
        else {
          console.log('User added to home screen');
        }
        deferredPrompt = null;
      });
    }
  });

})




var offset = null;
var limit = null;
var allowInfinite = true;
// Attach 'infinite' event handler
$$(document).on('infinite','.infinite-scroll-content', function () {

  var target = $(this).data("target");
  var url = $(this).data("url");
  if(limit == null)
  limit = $(this).data("limit");
if(offset == null)
  offset = $(this).data("offset");

  if (!allowInfinite) return;

  offset += limit;
  app.preloader.show();
  allowInfinite = false;

  app.request({
          url: app.methods.request(url),
          dataType: 'json',
          method: 'POST',
          data:{
            "offset":offset
          },
          cache: false,
          crossDomain: true,
          error: app.methods.errorFnc,
          success: function (data) {
             allowInfinite = true;
             app.preloader.hide();
             $.each(data.avisos,function(k,aviso){
                $$(target).append(`<li>
                    <a class="item-link item-content" href="/aviso/`+aviso.idaviso_pessoa+`/">
                      <div class="item-inner">
                        <div class="item-title-row">
                          <div class="item-title">
                            <span class="tag text-white `+(aviso.lido ? 'bg-success border-success' : 'bg-warning border-warning')+` py-1 px-2 float-end mt-1"><small>`+aviso.status+`</small></span>
                          </div>
                          <div class="item-after">`+aviso.data_envio+`</div>
                        </div>
                        <div class="item-subtitle">`+aviso.assunto+`</div>
                        <div class="item-text">`+aviso.conteudo+`</div>
                      </div>
                    </a>
                  </li>`);
             })
          }
      }); 

});

$$('body').off("click").on('click','.btnCancelarReserva', function () {
  
  var codigo = $$(this).data("codigo");

app.dialog.create({
                    title: 'Cancelamento de Reserva',
                    text: 'Tem certeza que deseja cancelar esta reserva?',
                    closeByBackdropClick: 'true',
                    buttons: [
                        {
                            text: 'Sim',
                            onClick: function () {
                                app.request({
                                  url: app.methods.request('/cancelarReserva'),
                                  dataType: 'json',
                                  method: 'POST',
                                  data:{
                                    "codigo":codigo
                                  },
                                  cache: false,
                                  crossDomain: true,
                                  error: app.methods.errorFnc,
                                  success: function (data) {
                                     app.preloader.hide();     
                                    app.views.main.router.navigate('/reservas/', {reloadCurrent: true,ignoreCache: true}); 
                                  }
                              });
                            },
                        },
                        {
                            text: 'Não',
                            
                        },
                    ],
                }).open();




});



/* pwa app install */
var deferredPrompt;
window.addEventListener('beforeinstallprompt', function (e) {
  console.log('beforeinstallprompt Event fired');
  e.preventDefault();
  deferredPrompt = e;
  return false;
});

  /* pwa app install */
$$('body').off("click").on('click','#addtohome', function () {
    if (deferredPrompt !== undefined) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(function (choiceResult) {

        if (choiceResult.outcome == 'dismissed') {
          console.log('User cancelled home screen install');
        }
        else {
          console.log('User added to home screen');
        }
        deferredPrompt = null;
      });
    }
  });






export default app;