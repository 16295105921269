export default {
  id: 'd42ed8f351',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class=page data-name=login><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title><h5 class=nome-administradora></h5></div><div class=right><!-- Link to close popup --> <a class=link href=/ ><i class="fal fa-times"></i></a></div></div></div><div class=page-content><div class="card ml-3 mr-3"><div class=content><div class="margin-top text-align-center mb-3"><div class=text-align-center><img src=static/images/logo-color.png width=40% alt=""></div></div><div class="block h-100 no-margin"><div class="text-align-center mb-3"><h3 class=font-14>Para recuperar a senha, informe o seu CPF</h3></div><div class=mensagemEsqueci></div><form class="was-validated needs-validation" novalidate><div class="list form-list no-margin margin-bottom"><ul><li class="item-content item-input item-input-with-value is-valid"><div class=item-inner><div class="item-title item-floating-label">Informe seu CPF</div><div class=item-input-wrap><input type=text name=usuario class="input-with-value cpf" id=usuarioEsqueci value=""></div></div></li></ul></div><button type=button class="button button-fill button-large button-raised margin-bottom color-theme btnEsqueci">Recuperar Senha</button></form></div></div></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};