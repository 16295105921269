
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '6480e7374f',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="page page-home" data-name=home><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=left><a href=# class="link panel-open" data-panel=left><i class="fal fa-bars"></i></a></div><div class=title><div class=logo-small><h5 class=nome-administradora></h5></div></div><div class=right><a href=/inicial/ class=link><i class="fal fa-home"></i></a></div></div></div><div class="page-content pl-2 pr-2 infinite-scroll-content infinite-scroll-bottom" data-target=.lista-avisos data-url=/avisos data-limit=';
      r += c(ctx_1.limit, ctx_1);
      r += ' data-offset=';
      r += c(ctx_1.offset, ctx_1);
      r += '><div class="card shadow-sm mb-4 mt-3 border-0"><div class=card-body><div class="list media-list"><ul class=lista-avisos>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.avisos, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><a class="item-link item-content" href=/aviso/';
          r += c(ctx_2.idaviso_pessoa, ctx_2);
          r += '/ ><div class=item-inner><div class=item-title-row><div class=item-title>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.lido, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' <span class="tag text-white bg-success border-success py-1 px-2 float-end mt-1"><small>';
              r += c(ctx_3.status, ctx_3);
              r += '</small></span> ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += ' <span class="tag text-white bg-warning border-warning py-1 px-2 float-end mt-1"><small>';
              r += c(ctx_3.status, ctx_3);
              r += '</small></span> ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div><div class=item-after>';
          r += c(ctx_2.data_envio, ctx_2);
          r += '</div></div><div class=item-subtitle>';
          r += c(ctx_2.assunto, ctx_2);
          r += '</div><div class=item-text>';
          r += c(ctx_2.conteudo, ctx_2);
          r += '</div></div></a></li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    