
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '86fded24a6',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="popup aviso"><div class=view><div class="page no-swipeback" data-name=grid><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title>Aviso</div><div class=right><!-- Link to close popup --> <a class="link popup-close iconeX"><i class="fal fa-times"></i></a></div></div></div><div class="page-content pl-2 pr-2"><div class="card card-style mb-2"><div class=content><div><h4 class=font-16><i class="fal ';
      r += c(ctx_1.chamado.tipo_icone, ctx_1);
      r += '"></i> ';
      r += c(ctx_1.chamado.tipo, ctx_1);
      r += ' - ';
      r += c(ctx_1.chamado.assunto, ctx_1);
      r += '</h4></div><div class="divider mb-2 mt-2"></div><h5 class="font-12 font-500 pb-2 pt-2 mb-0"><i class="fal fa-calendar-alt font-12 color-blue-dark pe-1"></i> Data da Solicitação: ';
      r += c(ctx_1.chamado.data_ocorrencia, ctx_1);
      r += '</h5><div class=d-flex><div><span class="badge ';
      r += c(ctx_1.chamado.cor, ctx_1);
      r += ' rounded-xl font-10">';
      r += c(ctx_1.chamado.status, ctx_1);
      r += '</span></div><div class=ms-auto><span class="badge ';
      r += c(ctx_1.chamado.cor_prioridade, ctx_1);
      r += ' rounded-xl font-10">Prioridade: ';
      r += c(ctx_1.chamado.urgente, ctx_1);
      r += '</span></div></div><div class="divider mb-2 mt-2"></div><div class=content>';
      r += c(ctx_1.chamado.descricao, ctx_1);
      r += '</div><div class="divider mb-2 mt-2"></div>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.anexos, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' <a href=';
          r += c(ctx_2.arquivo, ctx_2);
          r += '>';
          r += c(ctx_2.icone, ctx_2);
          r += '</a> ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '<div class="divider mb-2 mt-2"></div><div class=d-flex><div><h6 class="font-400 color-theme opacity-50 font-11 pt-1">Responsável</h6><h5 class="font-12 font-500 pb-2 pt-2 mb-0"><i class="fal fa-check-circle font-12 color-blue-dark pe-1"></i> ';
      r += c(ctx_1.chamado.responsavel, ctx_1);
      r += '</h5></div><div class="ms-auto text-center"><h6 class="font-500 font-11 opacity-50 pt-1 mb-0">interações</h6><h5 class="font-20 mb-1 color-green-dark pt-1">';
      r += c(ctx_1.chamado.interacoes, ctx_1);
      r += '</h5></div></div><div class="divider m-0"></div><h4 class="font-16 mt-3">Interações:</h4><div class="list media-list m-0 p-0"><ul class=chamados>';
      r += Template7Helpers.each.call(ctx_1, ctx_1.chamado.interacoes_lista, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<li><div><h4 class=font-10>';
          r += c(ctx_2.data, ctx_2);
          r += '</h4></div><div class=font-12>';
          r += c(ctx_2.descricao, ctx_2);
          r += '</div><div class="divider m-0"></div>';
          r += Template7Helpers.if.call(ctx_2, ctx_2.anexos_existe, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += ' ';
              r += Template7Helpers.each.call(ctx_3, ctx_3.anexos, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += ' <a href=';
                  r += c(ctx_4.arquivo, ctx_4);
                  r += '>';
                  r += c(ctx_4.icone, ctx_4);
                  r += '</a> ';
                  return r;
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [ctx_2, ctx_1]
              });
              r += ' ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '<div class="col-100 text-align-center font-12">nenhum anexo vinculado.</div>';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</li>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</ul></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    