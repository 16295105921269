export default {
  id: '4299943ff5',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="popup regra"><div class=view><div class="page no-swipeback" data-name=grid><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title>Aviso</div><div class=right><!-- Link to close popup --> <a class="link popup-close iconeX"><i class="fal fa-times"></i></a></div></div></div><div class="page-content pl-2 pr-2"><div class="card shadow-sm mb-4 conteudo"><div class="card-body mb-0"><h3 class="title m-0 p-0">';
      r += c(ctx_1.regra.tipo, ctx_1);
      r += '<br><small><small><small>';
      r += c(ctx_1.regra.condominio, ctx_1);
      r += '</small></small></small></h3></div><div class="card border-0"><div class=card-body><div>';
      r += c(ctx_1.regra.titulo, ctx_1);
      r += '</div><h5 class=mt-3>Horários Permitidos</h5><div>';
      r += c(ctx_1.regra.horario, ctx_1);
      r += '</div><h5 class=mt-3>Restrições</h5><div>';
      r += c(ctx_1.regra.restricao, ctx_1);
      r += '</div></div></div></div></div></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};