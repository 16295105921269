
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  id: '1e5a104955',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== "undefined" && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else return val;
        } else return "";
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '<div class="popup aviso"><div class=view><div class="page no-swipeback" data-name=grid><div class=navbar><div class=navbar-bg></div><div class=navbar-inner><div class=title>Aviso</div><div class=right><!-- Link to close popup --> <a class="link popup-close iconeX"><i class="fal fa-times"></i></a></div></div></div><div class="page-content pl-2 pr-2"><div class="card shadow-sm mb-4"><div class=card-body><div class="row mb-0"><div class=col-100><h5 class="title font-16">';
      r += c(ctx_1.aviso.assunto, ctx_1);
      r += '<br><small><small>Enviado em ';
      r += c(ctx_1.aviso.data_envio, ctx_1);
      r += '</small></small></h5></div></div></div><div class="card border-0"><div class="card-body conteudo">';
      r += c(ctx_1.aviso.html, ctx_1);
      r += '</div><div class=card-footer>';
      r += Template7Helpers.if.call(ctx_1, ctx_1.aviso.anexo1, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=mb-1><a href=';
          r += c(ctx_2.aviso.anexo1, ctx_2);
          r += ' class="external link mr-1 ml-1" target=_system><i class="fal ';
          r += c(ctx_2.aviso.anexo_icone1, ctx_2);
          r += '"></i><h6 class=ml-1>';
          r += c(ctx_2.aviso.anexo_nome1, ctx_2);
          r += '</h6></a></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.aviso.anexo2, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=mb-1><a href=';
          r += c(ctx_2.aviso.anexo2, ctx_2);
          r += ' class="external link mr-1 ml-1" target=_system><i class="fal ';
          r += c(ctx_2.aviso.anexo_icone2, ctx_2);
          r += '"></i><h6 class=ml-1>';
          r += c(ctx_2.aviso.anexo_nome2, ctx_2);
          r += '</h6></a></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.aviso.anexo3, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=mb-1><a href=';
          r += c(ctx_2.aviso.anexo3, ctx_2);
          r += ' class="external link mr-1 ml-1" target=_system><i class="fal ';
          r += c(ctx_2.aviso.anexo_icone3, ctx_2);
          r += '"></i><h6 class=ml-1>';
          r += c(ctx_2.aviso.anexo_nome3, ctx_2);
          r += '</h6></a></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.aviso.anexo4, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '<div class=mb-1><a href=';
          r += c(ctx_2.aviso.anexo4, ctx_2);
          r += ' class="external link mr-1 ml-1" target=_system><i class="fal ';
          r += c(ctx_2.aviso.anexo_icone4, ctx_2);
          r += '"></i><h6 class=ml-1>';
          r += c(ctx_2.aviso.anexo_nome4, ctx_2);
          r += '</h6></a></div>';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div></div></div></div></div></div></div>';
      return r;
    }(this);
  },
  styleScoped: false
};
    